<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="d-flex align-items-center mb-4">
          <country-flag class="mr-4" :country-iso="selectedCountry"></country-flag>
          <h2 class="font-weight-bolder text-dark mb-0">
            {{ $t("Assignment in") }} {{ getCountryName }}
          </h2>
        </div>
        <div class="text-dark-50 font-size-lg mb-1">
          <span class="font-weight-bold">{{ $t("Creation Date") }}:</span>
          {{ today }}
        </div>
        <div class="text-dark-50 font-size-lg">
          <span class="font-weight-bold">{{ $t("Number") }}:</span> {{ id }}
        </div>

        <div class="separator separator-dashed my-10"></div>

        <!--Sezione informazioni generali-->
        <div class="d-flex justify-content-between align-items-center mb-1">
          <h4 class="font-weight-bolder mb-0">{{ $t("Duration") }}</h4>
          <div>
            <b-btn variant="light-primary btn-sm" @click="jump(1)">
              <span class="svg-icon menu-icon">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
              <span class="font-weight-bolder">{{ $t("Edit Duration") }}</span>
            </b-btn>
          </div>
        </div>
        <div>
          <div class="d-flex align-items-center mb-1">
            <span class="font-size-lg">{{ $t("Start of assignment") }} </span>
            <span class="svg-icon svg-icon-md svg-icon-secondary mx-2">
              <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ dateStart ? DateService.format(dateStart, dateFormat) : dateStart }}
            </span>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="font-size-lg">{{ $t("End of assignment") }}</span>
            <span class="svg-icon svg-icon-md svg-icon-secondary mx-2">
              <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ dateEnd ? DateService.format(dateEnd, dateFormat) : dateEnd }}
            </span>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="font-size-lg mr-3">{{ $t("Assignment Duration ") }} ({{ $t("Days") }}):</span>
            <span class="font-weight-bolder font-size-h5">{{
            secondmentSumDays
          }}</span>
          </div>
          <div v-if="description != ''">
            <h4 class="font-weight-bolder mb-0">{{ $t("Description") }}</h4>
            <div>{{ description }}</div>
          </div>
        </div>

        <!--Sezione attività-->
        <div v-for="(item, i) in clientsData" :key="i">
          <div class="separator separator-dashed my-10"></div>
          <div class="d-flex justify-content-between align-items-center mt-8 mb-1">
            <h4 class="font-weight-bolder mb-0">{{ $t("Activities") }}</h4>
            <div>
              <b-btn variant="light-primary btn-sm" @click="jump(1)">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
                <span class="font-weight-bolder">{{ $t("Edit") + " " + $t("Activities") }}</span>
              </b-btn>
            </div>
          </div>
          <div>
            <div class="font-size-lg mb-2">
              {{ $t("Activities carried out at the customer's site") }}:
            </div>
            <ul class="ml-6">
              <li v-for="activity in item.extendedSelectedNacecodes" :key="activity.id"
                class="font-weight-bolder font-size-lg mb-2">
                {{ activity.name }}
              </li>
            </ul>
            <div v-if="item.noService">{{ item.noService }}</div>
          </div>
          <div v-if="item.selectedContractor">
            <div v-if="item.service == 'contract_work' && item.selectedContractor">
              <div class="separator separator-dashed my-10"></div>
              <div class="d-flex justify-content-between align-items-center mt-8 mb-1">
                <h4 class="font-weight-bolder mb-0">{{ $t("Contractor") }}:</h4>
                <div>
                  <b-btn variant="light-primary btn-sm" @click="jump(4)">
                    <span class="svg-icon menu-icon">
                      <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                    </span>
                    <span class="font-weight-bolder">{{ $t("Edit") + " " + $t("Contractor") }}</span>
                  </b-btn>
                </div>
              </div>
              <div class="text-dark-50 font-weight-bold line-height-lg">
                {{ $t("Name") }}:
                <span v-if="item.selectedContractor">
                  {{ item.selectedContractor.name }}
                </span>
              </div>
              <div class="text-dark-50 font-weight-bold line-height-lg">
                {{ $t("Registered office") }}:
                <span v-for="contractor_address in item.selectedContractor
            .addresses" :key="contractor_address.id">
                  <span v-if="contractor_address.kind == 0">
                    {{ contractor_address.address.formatted }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Sezione cliente-->
      <div class="col-6">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <h4 class="font-weight-bolder mb-0">{{ $t("Client") }}</h4>
          <div>
            <b-btn variant="light-primary btn-sm" @click="jump(1)">
              <span class="svg-icon menu-icon">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
              <span class="font-weight-bolder">{{ $t("Edit Client") }}</span>
            </b-btn>
          </div>
        </div>
        <div v-if="showAccordion">
          <div v-for="(item, index) in clientsData" :key="index">
            <div class="font-size-lg mb-1">
              {{ $t("Name") }}:
              <span class="font-weight-bolder">{{ item.client.name }}</span>
            </div>
            <div v-if="getClientFiscalId(item.client)" class="font-size-lg mb-1">
              {{ $t("Fiscal ID") }}:
              <span class="font-size-sm text-uppercase">
                {{ getClientFiscalId(item.client) }}
              </span>
            </div>
            <div v-if="getClientVat(item.client)" class="font-size-lg mb-1">
              {{ $t("VAT") }}:
              <span class="font-size-sm text-uppercase">
                {{ getClientVat(item.client) }}
              </span>
            </div>
            <div v-if="item.secondmentClient.registered_office" class="font-size-lg mb-1">
              {{ $t("Registered office") }}:
              <ul class="ml-6 font-size-sm">
                <li v-for="office in item.secondmentClient.registered_office" :key="office.id">
                  {{ office.address.formatted }}
                </li>
              </ul>
            </div>
            <div class="font-size-lg">{{ $t("Address") }}: <span class="font-weight-bolder">{{
            getExtendedClientAddress(item) }}</span></div>
            <div class="border mt-4">
              <address-map :lat="getClientAddressCenter(item).lat" :lng="getClientAddressCenter(item).lng"
                height="350"></address-map>
            </div>
          </div>
        </div>
        <b-card v-else no-body class="mt-2">
          <b-card-header class="p-1">
            <b-button block variant="primary">{{
            $t("No client found")
          }}</b-button>
          </b-card-header>
        </b-card>
      </div>
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <!--Sezione employees-->
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h4 class="font-weight-bolder mb-0">{{ $t("Employees") }}</h4>
          <div>
            <b-btn variant="light-primary btn-sm" @click="jump(1)">
              <span class="svg-icon menu-icon">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
              <span class="font-weight-bolder">{{ $t("Edit") + " " + $t("Employees") }}</span>
            </b-btn>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 font-size-lg">
        {{ $t("Selected Manager") }}:
        <span class="label label-light-info label-inline label-lg font-weight-bold">{{ managerInfo }}</span>
      </div>
      <div class="col-12">
        <div v-if="selectedEmployees">
          <datatable :items-provider="employees" :fields="tableEmployees.fields" :per-page.sync="tableEmployees.perPage"
            :per-page-options="tableEmployees.perPageOptions" :current-page.sync="tableEmployees.currentPage">
            <template #[`cell.name`]="{ item }">
              <div class="d-flex align-items-center">
                <Avatar :avatar-image="item?.persona?.avatar_display"
                  :avatar-text="`${item?.persona?.name?.[0]}${item?.persona?.surname?.[0]}`" />
                <div class="ml-3">
                  <div class="font-weight-bolder" :style="{ wordBreak: 'break-word' }">
                    {{ item.persona.name + " " + item.persona.surname }}
                  </div>
                  <div v-if="item.employee" class="text-muted">
                    {{ $t("Employee") }}
                  </div>
                  <div v-else class="text-muted">
                    {{ $t("Standard person") }}
                  </div>
                </div>
              </div>
            </template>

            <template #[`cell.dates`]="{ item }">
              <div>
                {{ $t("From") }}: {{ getEmployeeDates(item).split(" / ")[0] }}
              </div>
              <div>
                {{ $t("To") }}: {{ getEmployeeDates(item).split(" / ")[1] }}
              </div>
            </template>

            <template #[`cell.reversal`]="{ item }">
              {{ costalReversalCompany(item) }}
            </template>

            <template #[`cell.info`]="{ item }">
              <div class="d-flex">
                <EmployeeSalaryChip v-if="!isAfterSale" icon-only tooltip-placement="left"
                  :salary="item.salary_adjustment" @add-contract="$emit('add-contract', item?.persona?.id)">
                </EmployeeSalaryChip>
                <EmployeeAvailabilityChip :availability="getAvailabilityEnum(item)" :hover-msg="$t('View Gantt')"
                  :icon="icons.chart" icon-only tooltip-placement="right" class="pointer ml-2"
                  @click="onAvailabilityClick(item)">
                </EmployeeAvailabilityChip>
              </div>
            </template>

            <template #[`cell.documents`]="{ item }">
              <EmployeeDocumentsAvailabilityChip :item="item" :get-secondment-date="getSecondmentDate"
                :get-employees-id="getEmployeesId" :check-license-props="checkLicenseProps">
              </EmployeeDocumentsAvailabilityChip>
            </template>

            <template #[`cell.services`]="{ item }">
              <div v-if="moduleA1Enable">
                <div v-if="!hasModuleA13(item.employee)" class="form-group mb-3 mt-2">
                  <div class="radio-inline mt-2">
                    <label class="radio">
                      <input v-model="item.requestA1" type="radio" :value="true" @change="productRequest" />
                      <span></span>
                      {{ $t('Purchase the service') }}
                    </label>
                  </div>
                  <div v-if="item.requestA1" class="ml-6">
                    <div class="radio-inline">
                      <label :class="selectedCountry == 'IT' ? 'radio radio-danger' : 'radio'">
                        <input v-model="item.artKind" type="radio" value="ART12" @change="productRequest" />
                        <span></span>
                        {{ $t("Article 12") }}
                      </label>
                      <label :class="item.formA13IsEmpty ? 'radio radio-danger' : 'radio'">
                        <input v-model="item.artKind" type="radio" value="ART13" @change="article13edit(item)" />
                        <span></span>
                        {{ $t("Article 13") }}
                      </label>
                      <b-btn variant="light-primary btn-xs mb-2 ml-2" :disabled="item.artKind == 'ART12'"
                        @click="article13edit(item)">
                        <span class="svg-icon menu-icon">
                          <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                        </span>
                        <span class="font-weight-bolder">{{ $t("Edit") }}</span>
                      </b-btn>
                    </div>
                  </div>
                  <div class="radio-inline mt-2">
                    <label class="radio">
                      <input v-model="item.requestA1" type="radio" :value="false" @change="productRequest" />
                      <span></span>
                      {{ $t('Manage the A1 model independently') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <span class="font-weight-bold pr-3 form-label mt-2">{{
            $t("Transparency decree letter")
          }}</span>
                <b-form-checkbox v-model="item.TRASP" switch size="lg" @change="productRequest"></b-form-checkbox>
              </div>
            </template>
          </datatable>
        </div>
      </div>
      <div class="col-12 mb-8">
        <div class="separator separator-dashed my-10"></div>
        <h4 class="font-weight-bolder">{{ $t("Services") }}</h4>
        <p class="text-muted">
          {{ $t("Select the services required for the assignment") }}
        </p>
      </div>
      <div class="col-12">
        <h6 class="font-weight-bolder">
          {{
            $t(
              "ASSESSMENT OF COMMUNITY REQUIREMENTS FOR EU + CH + SEE COUNTRIES"
            )
          }}
        </h6>
        <div class="row">
          <div class="col-11">
            <h6 class="font-weight-bolder mb-0">{{ $t("ASSESSMENT OF COMMUNITY REQUIREMENTS FOR EU + CH + SEE COUNTRIES") }}
            </h6>
            <p class="mb-0">{{ $t("ASSESSMENT_DESCRIPTION") }}</p>
            <ul class="ml-10">
              <li>
                {{ $t("Declaration of Transnational Service Performance") }};
              </li>
              <li>{{ $t("Appointment of a contact person") }};</li>
              <li>
                {{
            $t(
              "Compliance with minimum working and wage conditions in the foreign country"
            )
          }};
              </li>
              <li>{{ $t("Request for Model A1") }}.</li>
            </ul>
            <p class="mb-0">
              {{ $t("In the event that one or more obligations do not emerge from a preliminary analysis, the consultants will report it to the company's contact persons, along with the obligations that must nevertheless be complied with")}}.
            </p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="declarationOfDetachment" switch size="lg"
                @change="productRequest"></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- Prodotto servizio Visti -->
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <h6 class="font-weight-bolder mb-0">{{ $t("VISA") }}</h6>
            <p class="mb-0">{{ $t("VISA_SERVICE_DESCRIPTION") }}</p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="visaService" switch size="lg" @change="productRequest"></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine prodotto servizio Visti -->
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <!-- {{ immigrationAssessmentText }} -->
            <h6 class="font-weight-bolder mb-0">{{ $t("ASSESSMENT IMMIGRATION") }}</h6>
            <p class="mb-5">{{ $t("ASSESSMENT_IMMIGRATION_DESCRIPTION") }}</p>
            <p v-if="immAsseMandatory" class="mt-3">
              {{ immAsseMandatoryText }}
            </p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="immigrationAssessment" switch size="lg" :disabled="immAsseMandatory"
                @change="productRequest"></b-form-checkbox>
            </div>
          </div>
        </div>
        <div v-if="moduleA1Enable" class="row">
          <div class="col-12">
            <div class="separator separator-solid mb-3"></div>
          </div>
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Schengen limits alert") }}</p>
            <p class="mb-0">{{ $t("Get notified when the Schengen limits are reached for the whole Schengen Area") }}
            </p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(schengenLimitAlert)}`">
                <span v-if="schengenLimitAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid my-3"></div>
          </div>
        </div>
        <div v-if="moduleA1Enable" class="row">
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Schengen country limits alert") }}</p>
            <p class="mb-0">{{ $t("Get notified when the Schengen limits are reached for the assignment country") }}</p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(schengenCountryLimitAlert)}`">
                <span v-if="schengenCountryLimitAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid mt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <h6 class="font-weight-bolder mb-0">{{ $t("RISK ASSESSMENT") }}</h6>
            <p class="mb-5">{{ $t("RISK_ASSESSMENT_SERVICE_DESCRIPTION") }}</p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="riskAssessment" switch size="lg" @change="productRequest"></b-form-checkbox>
            </div>
          </div>
          <div class="col-12">
            <p v-if="isMaxRiskLevel">
              <span class="label label-light-danger label-inline label-lg font-weight-bold">{{ maxRiskLevelText
                }}</span>
            </p>
            <p v-if="exceedPersonalFreedom">

              <span class="label label-light-danger label-inline label-lg font-weight-bold">
                {{ exceededPersonalFreedomText }}
              </span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="separator separator-solid mb-3"></div>
          </div>
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Risk Alert") }}</p>
            <p class="mb-0">{{ $t("Get notified if the risk level of the assigment change over time") }}</p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span v-b-tooltip.hover="$t('This service is included with RiskMap subscription.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(true)}`">
                <span>{{ $t("Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid my-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Natural disasters notification service") }}</p>
            <p class="mb-0">{{ $t("Get notified in case a natural disaster occurs during the assignment period") }}</p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(naturalDisastersAlert)}`">
                <span v-if="naturalDisastersAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid my-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Freedom Index monitoring service") }}</p>
            <p class="mb-0">{{ $t("FREEDOM_INDEX_DESCRIPTION") }} ({{ freedomIndexAlertLevel }})</p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(freedomIndexAlert)}`">
                <span v-if="freedomIndexAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid my-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Air Quality Index notification service") }}</p>
            <p class="mb-0">{{ $t("Get notified if the Air Quality Index report a level of pollution higher than notification threshold") }} ({{ pollutionLevelSetting }})</p>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end">
            <div class="font-weight-bold d-flex align-items-center">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(pollutionLevelAlert)}`">
                <span v-if="pollutionLevelAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid mt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <h6 class="font-weight-bolder mb-0">{{ $t("HEALTCARE REPORT") }}</h6>
            <p class="mb-5">{{ $t("HEALTCARE_REPORT_SERVICE_DESCRIPTION") }}</p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="healthcareReport" switch size="lg" @change="productRequest"></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="separator separator-solid mb-3"></div>
          </div>
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Mandatory vaccinations alert") }}</p>
            <p class="mb-0">{{ $t("Get notified if any mandatory vaccination is required to enter the country") }}</p>
          </div>
          <div class="col-1 col-offset-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(mandatoryVaccinationsAlert)}`">
                <span v-if="mandatoryVaccinationsAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid my-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-11 pl-10">
            <p class="font-weight-bolder mb-0">{{ $t("Recommended vaccinations alert") }}</p>
            <p class="mb-0">{{ $t("Get notified if the number of recommended vaccinations is higher than the threshold in company settings") }} ({{ complementaryVaccinationsAlertLevel }})</p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end">
              <span
                v-b-tooltip.hover="$t('This setting is managed by the company, administrators can manage it in company settings.')"
                class="label label-lg label-inline font-weight-bold py-4"
                :class="`label-light-${getCheckboxClass(complementaryVaccinationsAlert)}`">
                <span v-if="complementaryVaccinationsAlert">{{ $t("Active") }}</span>
                <span v-else>{{ $t("Not Active") }}</span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="separator separator-solid mt-3"></div>
          </div>
        </div>
      </div>
      <!-- Prodotto servizio set up comunicazione -->
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <h6 class="font-weight-bolder mb-0">{{ $t("GOVERNMENT TRAVEL COMMUNICATION SERVICE") }}</h6>
            <p class="mb-0">{{ $t("SERVICE_COMMUNICATION_DESCRIPTION") }}</p>
          </div>
          <div class="col-1">
            <div class="font-weight-bold d-flex align-items-center justify-content-end mb-3">
              <b-form-checkbox v-model="governmentTravelCommunicationService" switch size="lg"
                @change="productRequest"></b-form-checkbox>
            </div>
          </div>
          <div class="col-12"></div>
        </div>
      </div>
      <!-- Fine prodotto servizio set up comunicazione-->

      <div v-for="(item, i) in clientsData" :key="i" class="col-12">
        <div v-if="item.secondmentSuppliers.length > 0">
          <div class="separator separator-dashed my-10"></div>
          <div class="d-flex justify-content-between align-items-center mt-8 mb-1">
            <h4 class="font-weight-bolder mb-0">{{ $t("Suppliers") }}:</h4>
            <div>
              <b-btn variant="light-primary btn-sm" @click="jump(5)">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
                <span class="font-weight-bolder">
                  {{ $t("Edit") + " " + $t("Suppliers") }}
                </span>
              </b-btn>
            </div>
          </div>
          <secondment-suppliers-list :secondment-suppliers="item.secondmentSuppliers">

            <template #secondmentSupplier="{ secondmentSupplier }">
              <secondment-supplier-card disabled :value="secondmentSupplier" :secondment-dates-all="secondmentDatesAll">
              </secondment-supplier-card>
            </template>
          </secondment-suppliers-list>
        </div>
      </div>
      <div class="col-12">
        <div class="separator separator-dashed my-10"></div>
      </div>
      <!--      <div class="col-12">
        <h4 class="font-weight-bolder mb-6">{{ $t("Purchasable Reports") }}</h4>
        <div class="row">
          <div v-for="category in reportCategories" :key="category.id" class="col-4">
            <div v-if="!category.parent_id" class="card card-custom gutter-b card-stretch border">
              <div class="card-header ribbon ribbon-top justify-content-center border-0">
                <div v-if="!categoryChild(category.id).length" class="ribbon-target bg-warning"
                  style="top: -2px; right: 10px;">
                  {{ $t("Single Category") }}</div>
                <div v-if="categoryChild(category.id).length" class="ribbon-target bg-warning"
                  style="top: -2px; right: 10px;">
                  {{ $t("Multiple Category") }}</div>
                <h3 class="card-title mt-6">
                  <country-flag :country-iso="selectedCountry" big></country-flag>
                </h3>
              </div>
              <div class="card-body pt-4 d-flex flex-column justify-content-between">
                <div class="text-center mb-2">
                  <span class="font-weight-bolder text-uppercase">{{ category.name }}</span>
                </div>
                <div v-if="categoryChild(category.id).length" class="text-center mt-4">
                  {{ $t("This package include:") }}
                  <div v-for="subcategory in categoryChild(category.id)" :key="subcategory.id" class="mt-2">
                    <div class="label label-light-info label-inline label-lg font-weight-bold">
                      {{ subcategory.name }}
                    </div>
                  </div>
                </div>
                <div v-else class="text-center mt-4">
                  {{ postWithoutSubcategoriesText }}
                </div>
                <div v-if="category.parent_id" class="text-center">
                  <span class="label label-light-info label-inline label-lg font-weight-bold">
                    {{ reportCategories.filter(el => el.id == category.parent_id)[0].name }}</span>
                </div>
                <div class="text-center mt-8">
                  <button v-if="isReportInCart(category)" class="btn btn-danger font-weight-bolder text-uppercase w-75"
                    @click="removeItem(category.id + selectedCountry)">
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="/media/svg/icons/General/Trash.svg" />
                    </span>
                    {{ $t("Remove from cart") }}
                  </button>
                  <span v-else-if="isReportPending(category)" class="bg-warning rounded p-2">
                    {{ $t("The order is pending approval") }}</span>
                  <button v-else class="btn btn-success font-weight-bolder text-uppercase w-75"
                    @click="addToCartReport(selectedCountry, category)">
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                    </span>
                    {{ $t("Subscribe here") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <validation-observer ref="validationObserverArt" v-slot="{ handleSubmit }">
        <b-modal id="article-13" size="lg" hide-footer @hidden="onA13ModalHidden">

          <template #modal-header>
            <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
              <h4 class="mb-0">{{ $t("Request module A1 article 13") }}</h4>
              <div class="d-flex align-items-center">
                <b-button variant="success" class="mr-2" @click="handleSubmit(onA13Submit)">
                  {{ $t("Save Changes") }}
                </b-button>
                <b-button @click="closeArticle13Modal">
                  {{ $t("Cancel") }}
                </b-button>
              </div>
            </div>
          </template>
          <div class="form row">
            <div class="col-12 mb-3">
              <div class="alert alert-info" role="alert">
                {{ $t("Request module a13 for the state of ") }}
                <strong>{{ getCountryName }}</strong>,
                {{ $t('select additional countries if necessary') }}
              </div>
            </div>
            <div class="col-12">
              <validation-provider v-slot="{ errors }" :name="$t('Posting country')" vid="countries"
                :rules="{ a1_art13_secondment:selectedCountry }">
                <euro-select v-model="form.posting_country" :label="`${$t('Posting country')}*`"
                  :error-messages="errors" :multiselect-props="{
            options: a1ModuleCountries,
            'track-by': 'iso',
            label: 'name',
            searchable: true,
            multiple: true,
          }"></euro-select>
              </validation-provider>
            </div>
            <div class="col-6">
              <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From date')" rules="required">
                <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
                  :label="$t('From date') + '*'">
                </date-picker>
              </validation-provider>
            </div>
            <div class="col-6">
              <validation-provider v-slot="{ errors }" :name="$t('To date')" vid="to_date" rules="required">
                <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
                  :label="$t('To date') + '*'">
                </date-picker>
              </validation-provider>
            </div>
          </div>
        </b-modal>
      </validation-observer>
    </div>
    <secondment-participation-modal v-if="gantt.secondmentEmployee" :id="gantModalId" :availability="gantt.availability"
      :secondment-employee="gantt.secondmentEmployee" :overlapping-secondments="gantt.overlappingSecondments"
      :secondment-end-date="dateEnd" :secondment-start-date="dateStart" disable-edits
      @close="$bvModal.hide(gantModalId)" @hidden="onGanttModalHidden"></secondment-participation-modal>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import DateService from "@/core/services/date.service";
import SecondmentParticipationModal from "@/view/components/modals/SecondmentParticipationModal.vue";
import EmployeeAvailabilityChip from "@/view/components/chips/EmployeeAvailabilityChip.vue";
import EmployeeSalaryChip from "@/view/components/chips/EmployeeSalaryChip.vue";
import EmployeeDocumentsAvailabilityChip from "@/view/components/chips/EmployeeDocumentsAvailabilityChip.vue";
import AddressMap from "@/view/components/AddressMap.vue";
import icons from "@/core/config/icons.js";
import SecondmentSuppliersList from "@/view/components/lists/SecondmentSuppliersList.vue";
import SecondmentSupplierCard from "@/view/components/cards/SecondmentSupplierCard.vue";
import {
  perPageOptions,
  tableOptions,
} from "@/core/config/datatable.config.js";
import PostCategoryService from "@/core/services/post/post-category.service";
import Swal from "sweetalert2";
import moment from "moment";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import AvailabilityService from "@/core/services/availability.service";
import RiskService from "@/core/services/risk/risk.service";

export default {
  components: {
    SecondmentParticipationModal,
    EmployeeAvailabilityChip,
    EmployeeSalaryChip,
    SecondmentSuppliersList,
    SecondmentSupplierCard,
    AddressMap,
    CountryFlag,
    datePicker,
    EmployeeDocumentsAvailabilityChip,
  },

  props: {
    suppliers: {
      type: Array,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    isContractWork: {
      type: Boolean,
      default: false,
    },
    isIntragroupWork: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    selectedContractor: {
      type: Object,
      default: null,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    secondmentClient: {
      type: Object,
      default: null,
    },
    extendedNacecodes: {
      type: Array,
      required: true,
    },
    selectedCountry: {
      type: [String],
      default: "IT",
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    clientsData: {
      type: Array,
      default: () => [],
    },
    selectedEmployees: {
      type: Array,
      required: true,
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
    employeesDocumentsStatus: {
      type: Object,
      required: true,
    },
    intragroupClients: {
      type: Array,
      default: () => [],
    },
    productDeclarationOfDetachment: {
      type: Boolean,
      default: true,
    },
    productImmigrationAssessment: {
      type: Boolean,
      default: true,
    },
    productRiskAssessment: {
      type: Boolean,
      default: false,
    },
    productHealthcareReport: {
      type: Boolean,
      default: false,
    },
    productGovernmentTravelCommunicationService: {
      type: Boolean,
      default: false,
    },
    productVisaService: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      employees: this.selectedEmployees,
      icons,
      DateService,
      gantt: {
        secondmentEmployee: null,
      },
      reportCategories: [],
      postWithoutSubcategoriesText: this.$t(
        "This post category does not contain subcategories, inside you will find all the necessary information"
      ),
      declarationOfDetachmentText: this.$t(
        "Assessment of the obligation to declare secondment and appointment of a representative for transnational service provision in EU, EEA and Switzerland countries."
      ),
      immigrationAssessmentText: null,
      riskAssessmentText: null,
      // Servizi aggiuntivi
      declarationOfDetachment: this.productDeclarationOfDetachment,
      immigrationAssessment: this.productImmigrationAssessment,
      riskAssessment: this.productRiskAssessment,
      healthcareReport: this.productHealthcareReport,
      governmentTravelCommunicationService:
        this.productGovernmentTravelCommunicationService,
      visaService: this.productVisaService,
      form: {
        from_date: "",
        to_date: "",
        posting_country: [],
      },
      currentA13Employee: "",
      countryMaxRiskLevel: 1,
      exceededPersonalFreedomText: this.$t('The risk assessment product is recommended since the personal freedom exceeded the maximum advised parameter.'),
      maxRiskLevelText: this.$t("The risk assessment product is recommended as the country's maximum risk level is high (C or D)."),
      missingProducts: [],
    };
  },

  computed: {
    ...mapState("capabilities", ["pending"]),
    ...mapState("constants", [
      "countries",
      "PRODUCT_TYPES",
      "POSTING_CERTIFICATE_KIND",
    ]),
    ...mapGetters("user", [
      "getCurrentManagedCompany",
      "dateFormat",
      "getFocusSettings",
      "isAfterSale"
    ]),
    ...mapGetters("cart", ["getItems"]),
    ...mapGetters("constants", [
      "countriesMap",
      "schengenGroup",
      "moduleA1Group",
      "countryFromIso",
      "isEUCountry",
    ]),

    getEmployeesId() {
      return this.secondmentEmployees.map((el) => el.employee);
    },
    getSecondmentDate() {
      return {
        start: this.dateStart,
        end: this.dateEnd,
      };
    },
    isSelectedEmployee(employeeId) {
      return this.selectedEmployees.find((el) => el.id == employeeId)
        ? true
        : false;
    },
    gantModalId() {
      return `gantt-${this._uid}`;
    },
    getCountryName() {
      return this.countriesMap[this.selectedCountry]?.name;
    },

    moduleA1Enable() {
      return !!this.moduleA1Group.find(
        (el) => el.country === this.selectedCountry
      );
    },

    accordionSelected() {
      return {
        clientID: this.selectedClient.id,
        client: this.selectedClient,
        activities: this.extendedNacecodes,
        suppliers: this.suppliers,
        contractor: this.selectedContractor,
        secondmentClient: this.secondmentClient,
        extendedSelectedNacecodes: this.extendedNacecodes,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        isContractWork: this.isContractWork,
        isIntragroupWork: this.isIntragroupWork,
        service: this.service,
        show: true,
      };
    },
    managerInfo() {
      let manage = this.secondmentEmployees.filter((el) => el.manager);
      return manage.length ? manage[0].name : null;
    },
    showAccordion() {
      return this.clientsData.length || this.selectedClient;
    },

    today() {
      return DateService.format(new Date(), this.dateFormat);
    },

    tableEmployees() {
      const dataTable = {
        fields: [
          {
            label: this.$t("Name"),
            key: "name",
            sortable: false,
            show: true,
          },
          {
            label: this.$t("Dates"),
            key: "dates",
            show: true,
          },
          {
            label: this.$t("Informations"),
            key: "info",
            show: true,
          },
          {
            label: this.$t("Documents"),
            key: "documents",
            show: true,
          },
          {
            label: this.$t("Request services"),
            key: "services",
            show: this.schengenCountry,
          },
        ].filter((item) => item.show),
        isLoading: false,
        tableOptions: {
          tableOptions,
          sortBy: "id",
        },
        perPage: 10,
        perPageOptions: perPageOptions,
      };

      if (this.isIntragroupWork) {
        dataTable.fields.push({
          label: "Company reversal",
          key: "reversal",
        });
      }

      return dataTable;
    },
    secondmentSumDays() {
      return DateService.diff(this.dateEnd, this.dateStart) + 1;
    },
    toDateOverrides() {
      return this.form.from_date
        ? { minDate: moment(this.form.from_date) }
        : null;
    },
    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment(this.dateStart) } : null;
    },
    a1ModuleCountries() {
      let a1Countries = [];
      this.moduleA1Group.filter((element) => {

        a1Countries.push(this.countriesMap[element.country]);
      });
      a1Countries.sort((a, b) => a.name.localeCompare(b.name));
      return a1Countries;
    },

    schengenCountry() {
      return !!this.schengenGroup.find(
        (nation) => nation.country == this.selectedCountry
      );
    },

    immAsseMandatory() {
      // bisogna aggiungere anche il giorno di inizio cantiere
      let secondmentDays = this.secondmentSumDays;
      let countryEU = this.isEUCountry(this.selectedCountry);
      return secondmentDays > 90 && countryEU;
    },

    immAsseMandatoryText() {
      return this.$t(
        "Given that the chosen country is a member of the European Union and considering that the duration of the assignment exceeds 90 days, it is imperative to conduct an immigration assessment."
      );
    },

    isMaxRiskLevel() {
      const riskLevelSetting = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "risk_alert_level"
      );
      const alertValue = riskLevelSetting ? Number(riskLevelSetting.value) : 3; // falls back to 2 if not found
      return this.countryMaxRiskLevel >= alertValue;
    },

    exceedPersonalFreedom() {
      const freedomAlert = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "freedom_index_alert"
      );
      const alertValue = freedomAlert ? Number(freedomAlert.value) : 6.82; // falls back to 6.82 if not found

      let personalFreedom = parseFloat(
        this.countryFromIso(this.selectedCountry)?.freedom_index
          ?.personal_freedom
      );
      return personalFreedom < alertValue;
    },

    freedomIndexAlert() {
      const freedomAlert = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "freedom_index_alert"
      );
      return freedomAlert ? freedomAlert.value : true;
    },
    freedomIndexAlertLevel() {
      const freedomAlertLevel = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "freedom_index_alert_level"
      );
      return freedomAlertLevel ? Number(freedomAlertLevel.value) : 6.82;
    },
    naturalDisastersAlert() {
      const naturalDisastersSetting = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "natural_disasters_alert"
      );
      return naturalDisastersSetting ? naturalDisastersSetting.value : true;
    },
    schengenLimitAlert() {
      const schengenLimitSetting = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "schengen_limit_alert"
      );
      return schengenLimitSetting ? schengenLimitSetting.value : true;
    },
    schengenCountryLimitAlert() {
      const schengenCountryLimitSetting = this.getFocusSettings.find(
        (setting) =>
          setting.setting_type.name === "schengen_country_limit_alert"
      );
      return schengenCountryLimitSetting
        ? schengenCountryLimitSetting.value
        : true;
    },
    pollutionLevelSetting() {
      const pollutionLevelSetting = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "pollution_level_alert_level"
      );
      return pollutionLevelSetting ? Number(pollutionLevelSetting.value) : 151;
    },
    pollutionLevelAlert() {
      const pollutionLevelAlertSetting = this.getFocusSettings.find(
        (setting) => setting.setting_type.name === "pollution_level_alert"
      );
      return pollutionLevelAlertSetting
        ? pollutionLevelAlertSetting.value
        : true;
    },
    mandatoryVaccinationsAlert() {
      const mandatory_vaccinations_alert = this.getFocusSettings.find(
        (setting) =>
          setting.setting_type.name === "mandatory_vaccinations_alert"
      );
      return mandatory_vaccinations_alert
        ? mandatory_vaccinations_alert.value
        : true;
    },
    complementaryVaccinationsAlert() {
      const complementary_vaccinations_alert = this.getFocusSettings.find(
        (setting) =>
          setting.setting_type.name === "complementary_vaccinations_alert"
      );
      return complementary_vaccinations_alert
        ? complementary_vaccinations_alert.value
        : true;
    },
    complementaryVaccinationsAlertLevel() {
      const complementary_vaccinations_alert_level = this.getFocusSettings.find(
        (setting) =>
          setting.setting_type.name === "complementary_vaccinations_alert_level"
      );
      return complementary_vaccinations_alert_level
        ? Number(complementary_vaccinations_alert_level.value)
        : 10;
    },
    checkLicenseProps() {
      return {
        secondmentStart: this.secondmentStart,
        secondmentEnd: this.secondmentEnd,
      };
    },
  },

  watch: {
    dateStart: {
      immediate: true,
      handler(val) {
        this.secondmentStart = val;
      },
    },
    dateEnd: {
      immediate: true,
      handler(val) {
        this.secondmentEnd = val;
      },
    },
    secondmentEmployees: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(_) {
        this.supplementaryServices();
      },
    },
    id: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(_) {
        this.productRequest();
      },
    },
    selectedCountry: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(_) {
        // this.moduleA1Enable = !!this.moduleA1Group.find(el => el.country == country);
        this.loadMaxRiskLevel()
      }
    },
    riskAssessment: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (this.isMaxRiskLevel && !this.riskAssessment) {
          const title = this.exceedPersonalFreedom && this.isMaxRiskLevel
            ? this.$t("The country's maximum risk level is high (C or D) and the personal freedom exceeded the maximum advised parameter")
            : this.exceedPersonalFreedom ? this.$t("the personal freedom exceeded the maximum advised parameter")
              : this.$t("The country's maximum risk level is high (C or D)")
          Swal.fire({
            text: this.$t("Are you sure?"),
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("Yes, deactivated")
          }).then((result) => {
            if (result.isConfirmed) {
              this.riskAssessment = newValue
              Swal.fire({
                title: this.$t("Deactivated!"),
                text: this.$t("Your Risk Assessment has been deactivated."),
                icon: "success"
              });
            } else {
              this.riskAssessment = oldValue
            }
          });
        }
      },
    }
  },

  async mounted() {
    await PostCategoryService.getAllNotPaginated({
      country: this.selectedCountry,
      tree_view: true,
    }).then((res) => {
      this.reportCategories = res;
    });
    this.supplementaryServices();
    if (this.id) {
      this.productRequest();
    }
    this.loadMaxRiskLevel();
  },

  methods: {
    ...mapActions("cart", ["addItem", "addParentItem", "removeItem"]),
    getEmployeeDates(employee) {
      const emp = this.secondmentEmployees.find(se => se.employee === employee?.id);
      let msg = `${DateService.format(emp.date_start, this.dateFormat)} / ${DateService.format(
        emp.date_end,
        this.dateFormat,
      )}`;
      return msg;
    },

    isSelected(employeeId) {
      return this.selectedEmployees.find((el) => el.id == employeeId)
        ? true
        : false;
    },

    loadMaxRiskLevel() {
      RiskService.getCountryMaxRiskLevel(this.selectedCountry).then(countryMaxRiskLevel => {
        this.countryMaxRiskLevel = countryMaxRiskLevel.max_risk;
        if (this.exceedPersonalFreedom || this.isMaxRiskLevel) {
          this.riskAssessment = true;
        }
      }).catch(e => {
        console.log('Err Risk Map', e)
      })
    },

    getCheckboxClass(alertValue) {
      return alertValue ? "success" : "danger";
    },

    handleJump(step, payload) {
      if (payload.client.id !== this.selectedClient.id) {
        const idxToRemove = this.clientsData.indexOf(
          this.clientsData.find((el) => el.clientID === payload.client.id)
        );
        this.clientsData.splice(idxToRemove, 1);
        this.clientsData.push(this.accordionSelected);
        this.$emit("modify-client", payload);
      }
      this.jump(step);
    },
    jump(step) {
      this.$emit("jump", step);
    },

    onGanttModalHidden() {
      this.gantt.secondmentEmployee = null;
      this.gantt.availability = 0;
      this.gantt.overlappingSecondments = [];
    },

    async onAvailabilityClick(employee) {
      if (!this.isSelected(employee.id)) {
        this.selectedEmployees = [...this.selectedEmployees, employee];
      }
      await this.$nextTick();
      this.gantt.secondmentEmployee = this.secondmentEmployees.find(se => se.employee == employee.id);
      this.gantt.availability = this.getAvailabilityEnum(employee)
      this.gantt.overlappingSecondments = AvailabilityService.checkOverlappingSecondments(this.dateStart, this.dateEnd, employee.availability_data.busy_dates);
      await this.$nextTick();
      this.$bvModal.show(this.gantModalId);
    },

    getClientFiscalId(actor) {
      if (actor.fiscalid_set) {
        const fiscalId = actor.fiscalid_set.map((el) => el.fiscal_ID);
        return fiscalId.join(", ");
      } else if (actor.fiscal_id) {
        const vat = actor.fiscal_id.map((el) => el.fiscalid);
        return vat.join(", ");
      } else if (actor.companyfiscalid_set) {
        const vat = actor.companyfiscalid_set.map((el) => el.fiscalid);
        return vat.join(", ");
      }
    },

    getClientVat(actor) {
      if (actor.vat) {
        const vat = actor.vat.map((el) => el.vat);
        return vat.join(", ");
      } else if (actor?.individual_company_manager) {
        const extracompanyvat_set =
          actor.individual_company_manager[0]?.extracompanyvat_set;
        if (extracompanyvat_set != undefined) {
          const vat = extracompanyvat_set.map((el) => el.vat);
          return vat.join(", ");
        }
      } else if (actor?.extracompanyvat_set) {
        const vat = actor.extracompanyvat_set.map((el) => el.vat);
        return vat.join(", ");
      }
    },

    getExtendedClientAddress(item) {
      const a = item.client.addresses.find(
        (x) =>
          x?.address?.id === item.secondmentClient.address ||
          x.id === item.secondmentClient.address
      );
      if (!a) return "";
      return a?.address?.formatted || a?.formatted;
    },

    getClientAddressCenter(item) {
      const r = {
        lat: null,
        lng: null,
      };
      if (!item.client) return r;
      const addressData = item.client.addresses.find(
        (x) =>
          x?.address?.id === item.secondmentClient.address ||
          x.id === item.secondmentClient.address
      );
      if (!addressData) {
        return r;
      }
      if (!addressData?.latitude || !addressData?.longitude) {
        if (
          !addressData?.address?.latitude ||
          !addressData?.address?.longitude
        ) {
          return r;
        }
      }
      r.lat = addressData?.address?.latitude || addressData.latitude;
      r.lng = addressData?.address?.longitude || addressData.longitude;
      return r;
    },

    costalReversalCompany(employee) {
      let company = this.getCurrentManagedCompany.company_name;
      this.intragroupClients.forEach((el) => {
        if (el.id == employee.cost_reversal) {
          company = el.name;
          return;
        }
      });
      return company;
    },

    addToCartReport(country, item) {
      let parentItems = {};
      const isParentWithChildren = !item.parent_id;
      if (isParentWithChildren) {
        parentItems = this.reportCategories.filter(
          (el) => el.parent_id == item.id
        );
        if (parentItems.length) {
          let info = "<ul>";
          parentItems.forEach((el) => {
            info += `<li>- ${el.name}</li>`;
          });
          info += "</ul>";
          Swal.fire({
            icon: "info",
            title:
              "In questo modo aggiungerai anche le sottocategorie nel carrello:",
            html: '<div style="text-align:left">' + info + "</div>",
          });
        }
      }
      const newItem = {
        name: item.name,
        slug: item.slug,
        type: "report",
        price: item.report_price,
        countries: [country],
        categories: [item.id],
        id: item.id + country,
      };
      if (isParentWithChildren) {
        this.addParentItem({ item: newItem, children: parentItems });
      } else {
        this.addItem(newItem);
      }
    },

    isReportPending(item) {
      let pendingCountry = this.pending.find(
        (order) => this.selectedCountry == order.country
      );
      return pendingCountry
        ? pendingCountry.categories.some(
          (category) =>
            category.id == item.id || category.id == item.parent_id
        )
        : false;
    },

    isReportInCart(item) {
      let items = this.getItems;
      return item && items.length
        ? items.some((el) => el.id == item.id + this.selectedCountry)
        : false;
    },

    categoryChild(id) {
      return this.reportCategories.filter((el) => el.parent_id == id);
    },

    hasModuleA13(id) {
      let document = this.employeesDocumentsStatus[id];
      if (document) {
        return (
          document.postingcertificate_art13 == "valid" ||
          document.postingcertificate_art13 == "expiring"
        );
      }
      return false;
    },

    supplementaryServices() {
      const requestA1 = this.getFocusSettings.find(
        (setting) =>
          setting.setting_type.name === "a1_posting_certificate_service"
      );
      const settingValue = requestA1 ? requestA1.value : true; // falls back to true if not found
      this.employees = this.selectedEmployees.map((el) => ({
        ...el,
        requestA1: el.requestA1 || settingValue,
        artKind: el.artKind || "ART12",
        TRASP: el.TRASP || false,
        formA13: {
          from_date: '',
          to_date: '',
          posting_country: [this.selectedCountry],
        },
        formA13IsEmpty: el.formA13IsEmpty || false,
      }));
    },

    productRequest() {
      let products = [];
      this.missingProducts = [];
      this.employees.forEach((employee) => {
        if (employee.requestA1) {
          const a1Products = this.moduleA1Products(employee);
          if (a1Products.length) {
            products.push(...a1Products);
          }
        }
        const traspProduct = this.transparencyLetterProduct(employee);
        if (traspProduct) {
          products.push(traspProduct);
        }
      });
      const dddProduct = this.declarationOfDetachmentProduct();
      if (dddProduct) {
        products.push(dddProduct);
      }
      const immProduct = this.immigrationAssessmentProduct();
      if (immProduct) {
        products.push(immProduct);
      }
      const riskProduct = this.riskAssessmentProduct();
      if (riskProduct) {
        products.push(riskProduct);
      }
      const healthcareReportProduct = this.healthcareReportProduct();
      if (healthcareReportProduct) {
        products.push(healthcareReportProduct);
      }
      const governmentTravelCommunicationServiceProduct =
        this.governmentTravelCommunicationServiceProduct();
      if (governmentTravelCommunicationServiceProduct) {
        products.push(governmentTravelCommunicationServiceProduct);
      }
      const visaServiceProduct = this.visaServiceProduct();
      if (visaServiceProduct) {
        products.push(visaServiceProduct);
      }
      if (products.length) {
        this.$emit("update:products", products);
      }
      this.$emit("update:missingProducts", this.missingProducts);
    },

    moduleA1Products(employee) {
      let products = [];
      const baseAttributes = {
        product_type: this.PRODUCT_TYPES.POSTING_CERTIFICATE,
      };
      if (employee.artKind == this.POSTING_CERTIFICATE_KIND.ART12 && this.selectedCountry != "IT") {
        products.push({
          attributes: {
            certificate_kind: this.POSTING_CERTIFICATE_KIND.ART12,
            secondment_employee: this.secondmentEmployees.find(el => el.employee == employee.id)?.id,
            countries: [this.selectedCountry],
            posting_country: [this.selectedCountry],
            persona: employee.persona.id,
            from_date: this.dateStart,
            to_date: this.dateEnd,
            secondment: this.id,
          },
          ...baseAttributes,
        });
      } else if (
        employee.artKind == this.POSTING_CERTIFICATE_KIND.ART13 &&
        employee.formA13.posting_country.length > 1
      ) {
        employee.formA13IsEmpty = false;
        products.push({
          attributes: {
            certificate_kind: this.POSTING_CERTIFICATE_KIND.ART13,
            secondment_employee: this.secondmentEmployees.find(el => el.employee == employee.id)?.id,
            countries: [this.selectedCountry],
            posting_country: employee.formA13.posting_country,
            persona: employee.persona.id,
            from_date: employee.formA13.from_date,
            to_date: employee.formA13.to_date,
            secondment: this.id,
          },
          ...baseAttributes,
        });
      } else if (
        (employee.artKind == this.POSTING_CERTIFICATE_KIND.ART13 &&
          employee.formA13.posting_country.length < 2) || (employee.artKind == this.POSTING_CERTIFICATE_KIND.ART12 && this.selectedCountry == "IT")
      ) {
        employee.formA13IsEmpty = true;
        this.missingProducts.push({
          attributes: {
            certificate_kind: employee.artKind,
            name: employee.persona.name,
            surname: employee.persona.surname,
          },
          ...baseAttributes,
        });
      }
      return products;
    },

    transparencyLetterProduct(employee) {
      if (employee.TRASP)
        return {
          attributes: {
            secondment_employee: this.secondmentEmployees.find(el => el.employee == employee.id)?.id,
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.TRANSPARENCY_LETTER,
        };
    },

    declarationOfDetachmentProduct() {
      if (this.declarationOfDetachment)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.DECLARATION_OF_DETACHMENT,
        };
    },

    immigrationAssessmentProduct() {
      if (this.immigrationAssessment)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.IMMIGRATION_ASSESSMENT,
        };
    },

    riskAssessmentProduct() {
      if (this.riskAssessment)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.RISK_ASSESSMENT,
        };
    },

    healthcareReportProduct() {
      if (this.healthcareReport)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.HEALTHCARE_REPORT,
        };
    },

    governmentTravelCommunicationServiceProduct() {
      if (this.governmentTravelCommunicationService)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type:
            this.PRODUCT_TYPES.GOVERNMENT_TRAVEL_COMMUNICATION_SERVICE,
        };
    },

    visaServiceProduct() {
      if (this.visaService)
        return {
          attributes: {
            secondment: this.id,
            countries: [this.selectedCountry],
          },
          product_type: this.PRODUCT_TYPES.VISA_SERVICE,
        };
    },

    onA13ModalHidden() {
      this.form = {
        from_date: "",
        to_date: "",
        posting_country: [],
      };
      this.currentA13Employee = "";
      this.productRequest();
    },

    article13edit(item) {
      if (item.artKind == "ART13") {
        if (item.formA13) {
          this.form = JSON.parse(JSON.stringify(item.formA13));
        }
        this.currentA13Employee = item;
        this.$bvModal.show("article-13");
      }
    },

    closeArticle13Modal() {
      this.$bvModal.hide("article-13");
    },

    onA13Submit() {
      if (this.form) {
        this.currentA13Employee.formA13 = this.form;
      }
      this.$bvModal.hide("article-13");
    },

    getAvailabilityEnum(employee) {
      return AvailabilityService.checkAvailability(employee.date_start, employee.date_end, employee.availability_data.busy_dates);
    },
  },
};
</script>

<style lang="scss" scoped>
.review-step {
  max-width: 800px;
  margin: 0 auto;
}
</style>
